.navbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 400;
}

.webNav {
  display: flex;
  margin: 0;
  padding: 0;
  line-height: 64px;
}

.webNav > a > li {
  position: relative;
  display: block;
  white-space: nowrap;
  top: 1px;
  float: left;
  padding: 0 20px;
  margin: 0;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: rgb(233, 233, 233);
  text-decoration: none;

  /* &:hover {
    border-bottom: 2px solid color-list("accent");
    color: color-list("accent");
    background-color: transparent;
  }

  &.selected {
    border-bottom: 2px solid color-list("primary");
  }

  @include media('<tablet'){
    padding: 0 5px;
    font-size: 12px;
  }  */
}

.menuToggle {
  display: none;
}
.mobileNav {
  display: none;
}
.mobileNavClosed {
  display: none;
}

@media screen and (max-width: 767px) {
  .webNav {
    display: none;
  }
  .mobileNav {
    display: flex;
    flex-direction: column;
    background-color: #084887;
    height: 100vh;
    width: 250px;
    position: fixed;
    top: 0;
    right: 0;
    padding: 0;
    margin: 0;
    list-style: none;
    transition: right 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
    z-index: 400;
  }
  .mobileNav > li {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .mobileNav > li:hover {
    background-color: #3798bf;
  }
  .mobileNav > li > a {
    width: 100%;
    position: relative;
    padding: 0.75em 2em;
    text-decoration: none;
    color: rgb(233, 233, 233);
    cursor: pointer;
    font-size: 1.1em;
  }
  .mobileNavClosed {
    display: flex;
    flex-direction: column;
    background-color: #084887;
    height: 100vh;
    width: 250px;
    position: fixed;
    top: 0;
    right: -250px;
    padding: 0;
    margin: 0;
    list-style: none;
    transition: right 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
    z-index: 400;
  }
  .mobileNavClosed > li {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .mobileNavClosed > li:hover {
    background-color: #3798bf;
  }
  .mobileNavClosed > li > a {
    width: 100%;
    position: relative;
    padding: 0.75em 2em;
    text-decoration: none;
    color: rgb(233, 233, 233);
    cursor: pointer;
    font-size: 1.1em;
  }
  .menuToggle {
    position: absolute;
    top: 1rem;
    right: 1rem;
    display: block;
    background-color: transparent;
    fill: #fff;
    border: none;
  }
  .menuToggle > svg {
    width: 40px;
    height: 40px;
  }
  .closeButton {
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: #fff;
    background: transparent;
    border: none;
    font-size: 2em;
  }
  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 4.5rem;
    margin-bottom: 1.5em;
    line-height: 2em;
  }

  .title > h1 {
    color: white;
    font-size: 1.5em;
    margin: 0;
  }

  .headersubtitle {
    position: relative;
    padding: 0 0.5rem;
    margin: 0;
    font-size: 1rem;
    font-style: italic;
    color: white;
  }

  .headersubtitle:before {
    position: absolute;
    background-color: white;
    width: 20px;
    height: 1px;
    content: '';
    left: auto;
    right: 100%;
    top: 50%;
  }

  .headersubtitle:after {
    position: absolute;
    background-color: white;
    width: 20px;
    height: 1px;
    content: '';
    left: 100%;
    right: auto;
    top: 50%;
  }
}
