.singleProject {
  position: relative;
  min-height: 100vh;
  background: #0d1321;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.singleProject > h1 {
  color: #f7f7f2;
  font-size: 3em;
  margin-top: 2em;
  margin-bottom: 0;
}

.singleProject > p {
  max-width: 760px;
  padding: 1em 2em;
  color: #f7f7f2;
}

.image {
  position: relative;
  height: auto;
  width: 760px;
  overflow: hidden;
  margin: 2rem 0;
}

.image > img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.links {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 6rem;
}

.links > a {
  padding: 0.25rem 1rem;
}

@media screen and (max-width: 767px) {
  .image {
    height: auto;
    width: 360px;
  }
  .singleProject > p {
    max-width: 360px;
  }
}

@media screen and (max-width: 375px) {
  .image {
    height: auto;
    width: 250px;
  }
  .singleProject > p {
    max-width: 250px;
  }
}
