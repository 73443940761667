.home {
  position: relative;
  height: 100vh;
  background: url('../../assets/computerdark.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.homeOverlay {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: rgba(8, 72, 135, 0.3);
}

.typingWrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #f7f7f2;
}

.headerIntro {
  margin: 0 auto;
  padding: 0;
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.headerName {
  font-size: 4rem;
  font-weight: 700;
}

.MyTypist {
  margin-top: 1em;
  font-size: 1.8em;
}

.headerTypewriter {
  margin: 0 auto;
  margin-top: 2em;
  border-right: 2px solid rgba(247, 247, 242, 0.75);
  text-align: center;
  font-size: 1.8rem;
  white-space: nowrap;
  overflow: hidden;
  animation: typewriter 4s steps(38) 1s 1 normal both,
    blink-caret 1s step-end infinite;
}

@media screen and (max-width: 767px) {
  .headerIntro {
    font-size: 1.5rem;
    margin-bottom: 0.5em;
  }
  .headerName {
    font-size: 4rem;
  }
  .headerTypewriter {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 504px) {
  .headerTypewriter {
    font-size: 1em;
  }
}

@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 20em;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: #3798bf;
  }
}
