.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  width: 100%;
  height: 64px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: #f7f7f2;
}

.copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 50px;
  text-align: center;
  font-size: 1rem;
  font-style: italic;
}
