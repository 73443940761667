.projects {
  position: relative;
  background: #0d1321;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 5em;
}

.projects > h1 {
  color: #f7f7f2;
  font-size: 3em;
  margin-top: 3em;
  margin-bottom: 0;
}

.toggle {
  width: 100%;
  max-width: 1180px;
  border-bottom: 1px solid #f7f7f2;
  margin-top: 1em;
  margin-bottom: 0;
}

.button {
  padding: 0.5em 1em;
  background: #084887;
  font-size: 1.2em;
  border: 1px solid #f7f7f2;
  border-bottom: none;
  cursor: pointer;
  color: #f7f7f2;
}
.button:hover, .button:focus {
  background: #3798bf;
}
.activeButton {
  padding: 0.5em 1em;
  background: #3798bf;
  font-size: 1.2em;
  border: 1px solid #f7f7f2;
  border-bottom: none;
  cursor: pointer;
  color: #f7f7f2;
}

.portRow {
  width: 100%;
  max-width: 1180px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0 0 2rem 0;
  animation: slideInBottom 0.75s ease both;
  border: 1px solid #f7f7f2;
  border-top: none;
}

.portRow > a {
  text-decoration: none;
}

@keyframes slideInBottom {
  0% {
    opacity: 0;
    transform: translateY(300px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (max-width: 440px) {
  .toggle {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
