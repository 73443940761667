.about {
  position: relative;
  min-height: 100vh;
  background: #0d1321;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 4em;
}

.about > h1 {
  color: #f7f7f2;
  font-size: 3em;
  margin-top: 3em;
  margin-bottom: 0;
}

.about > p {
  max-width: 1200px;
  padding: 1rem 4rem;
}

.center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  flex-wrap: wrap;
}

.images {
  display: block;
  max-width: 800px;
  padding: 0 1rem;
}
.images > img {
  margin: 1.5rem 1.5rem 1.5rem 0;
  height: 300px;
  width: 300px;
  animation: slideInLeft 0.75s ease both;
}
.images > p {
  margin: 1.5rem 0.75rem;
  color: #f7f7f2;
  animation: slideInRight 0.75s ease both;
}
.images > p > a {
  color: #3798bf;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .about {
    margin: 0;
  }
}
@media screen and (max-width: 525px) {
  .images {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .images > img {
    height: 200px;
    width: 200px;
    margin: 0;
  }
}

@keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(300px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(-300px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInBottom {
  0% {
    opacity: 0;
    transform: translateY(300px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
